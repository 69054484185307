// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyAKr3hFglKk-IgKD9Z2ElfEbU3RnXOLc7s",
    // authDomain: "ring-of-fire-2d38a.firebaseapp.com",
    // databaseURL: "https://ring-of-fire-2d38a.firebaseio.com",
    // projectId: "ring-of-fire-2d38a",
    // storageBucket: "ring-of-fire-2d38a.appspot.com",
    // messagingSenderId: "843114632066",
    // appId: "1:843114632066:web:f6440923ec59c83121f59b"

    apiKey: "AIzaSyAf4gCRLnI7vLIPAZClJj_YJN1jSGGHCyI",
    authDomain: "rof-cvo.firebaseapp.com",
    databaseURL: "https://rof-cvo-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rof-cvo",
    storageBucket: "rof-cvo.appspot.com",
    messagingSenderId: "866491945138",
    appId: "1:866491945138:web:6c77a8ec03a5c5d5c079ab"


  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
