<h1 mat-dialog-title>Add new player</h1>



<div mat-dialog-content>
  <p>Please enter the name of a player.</p>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [disabled]="name.length == 0" [mat-dialog-close]="name" cdkFocusInitial>Ok</button>
</div>