<app-player (click)="editPlayer(i)" *ngFor="let player of game.players; let i = index;"
    [ngStyle]="{'top.px': 20 + (i * 70)}" [playerActive]="i == game.currentPlayer" [image]="game.player_images[i]"
    [name]="player" class="player-position hide-mobile"></app-player>


<div class="scroll-container hide-desktop">
    <app-player-mobile (click)="editPlayer(i)" *ngFor="let player of game.players; let i = index;"
        [image]="game.player_images[i]" [playerActive]="i == game.currentPlayer" [name]="player"></app-player-mobile>
</div>

<div *ngIf="gameOver" class="gameOver">
    <img src="./assets/img/game_over.svg">
</div>

<div *ngIf="!gameOver" class="field" style="background-image: url('./assets/img/board.jpg');">
    <div class="card-stack">
        <!-- Card stack  -->
        <img *ngFor="let c of [0, 1, 2, 3]; let i = index;" [ngStyle]="{'right.px': i * 5}"
            src="assets/img/cards/card_cover.png">
        <img (click)="takeCard()" class="top-card" style="right: 25px" src="./assets/img/cards/card_cover.png">


        <!-- Stack for played cards-->
        <img *ngFor="let card of game.playedCards" class="played-card" src="./assets/img/cards/{{ card }}.png">


        <!-- Current card (Animation) -->
        <img (click)="takeCard()" *ngIf="game.pickCardAnimation" class="pick-card" style="right: 25px"
            src="./assets/img/cards/{{ game.currentCard }}.png">
    </div>
</div>

<app-game-info *ngIf="!gameOver" [card]="game.currentCard" class="pin-bottom"></app-game-info>

<button *ngIf="!gameOver" mat-fab color="primary" (click)="openDialog()" class="btn-add">
    <mat-icon>add</mat-icon>
</button>

<img class="preloadImage" src="./assets/img/cards/{{ game.stack[game.stack.length -1] }}.png">
